import React from 'react'
import { graphql, Link } from 'gatsby'
import Parallax from 'react-rellax'

import Layout from '../components/layout'
import Prefooter from '../components/prefooter'
import SectionHeader from '../components/section-header'
import PageHeader from '../components/page-header'
import Card from '../components/card'
import LinkCard from '../components/link-card'
import NewsletterSignup from '../components/newsletter-signup'
import Services from '../components/services'
import Block from '../components/waypoint-block'
import VideoBlock from '../components/waypoint-video'
import ColorSwitch from '../components/waypoint-color-switch'
import ImageSlider from '../components/image-slider'
import Drift from '../components/waypoint-drift'

import DownArrow from '../components/Icons/ArrowDown'
import { useI18next, Helmet, useTranslation } from 'gatsby-plugin-react-i18next'
import { useIsFrench } from '../hooks/use-is-language'

function ClientList({ clientList, filter, showAllClients, setShowAllClients }) {
  if (clientList.length < 1) {
    return <p>None found in this type</p>
  }

  let clients = clientList.slice()
  if (filter !== 'All') {
    clients = clients.filter(client =>
      client.data.company_type.includes(filter)
    )
  }

  const displayClients = clients.map((client, index) => (
    <Card
      key={index}
      classes={client.data.company_type
        .toLowerCase()
        .split(', ')
        .join(' ')}
    >
      <img
        className="client-logo"
        src={client.data.logo.url}
        alt={client.data.name}
      />
      <div className="color-secondary small">
        {client.data.tags}
        &nbsp;
      </div>
      <h5>{client.data.lede}</h5>
      <p>{client.data.description}</p>
      {/* Sometimes, Prismic sends empty data. Check for that. */}
      {client.data.urls.length > 0 && client.data.urls[0].url_text !== null && (
        <div className="client-urls">
          {client.data.urls.map((link, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && <span>|</span>}
                {link.url !== null ? (
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.url_text}
                  </a>
                ) : (
                  link.url_text
                )}
              </React.Fragment>
            )
          })}
        </div>
      )}
    </Card>
  ))

  return (
    <div className="cards grid grid--3col">
      {displayClients}
      {!showAllClients && clients.length > 6 && (
        <div className="c-client-cards__view-all-button">
          <button
            onClick={() => {
              setShowAllClients(current => !current)
            }}
          >
            View all recent projects
          </button>
        </div>
      )}
    </div>
  )
}

function IndexPage({ data }) {
  const [filter, setFilter] = React.useState('All')
  const [showAllClients, setShowAllClients] = React.useState(false)

  const { language } = useI18next()
  const [t] = useTranslation(['translations', 'seo'])

  const links = data.allPrismicRecentLinks.edges[0].node.data.links
  const clients = data.allPrismicClients.edges.map(item => item.node)
  const photos = data.allPrismicPhotoGallery.edges[0].node.data.photos.map(
    item => ({
      slide: {
        url: item.photo.url,
        alt: item.name,
      },
    })
  )
  const clientFilters = clients
    .map(client => client.data.company_type.split(/,\s*/))
    .reduce((acc, val) => acc.concat(val), ['All'])
    .filter((item, index, arr) => arr.lastIndexOf(item) === index)
    .sort()

  const isFrench = useIsFrench()

  return (
    <Layout>
      <Helmet
        title={t('HomePage.title', { ns: 'seo' })}
        meta={[
          {
            name: 'description',
            content: t('HomePage.description', { ns: 'seo' }),
          },
          {
            name: 'keywords',
            content: t('HomePage.keywords', { ns: 'seo' }),
          },
          {
            property: 'og:title',
            content: t('HomePage.ogTitle', { ns: 'seo' }),
          },
          {
            property: 'og:description',
            content: t('HomePage.ogDescription', { ns: 'seo' }),
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-index.jpg',
          },
          { property: 'og:url', content: 'https://versett.com/' },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Versett' },
          {
            name: 'facebook-domain-verification',
            content: 'ykxlbhccqzoo2c2sa2x1chmt63l8id',
          },
        ]}
      >
        <html lang={language} />
      </Helmet>
      <div
        className="t-interstitial"
        style={{
          fill: 'none',
          stroke: 'white',
          backgroundColor: 'black',
          color: 'white',
        }}
      >
        <svg
          viewBox="0 0 436 236"
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          style={{ stroke: 'none', fill: 'white' }}
        >
          <path d="M48.88.89l73.26 170.25L196.43.89h48.5L137.62 235.11h-31L.38.89h48.5zM297.54 1L190.47 235H233L340.11 1h-42.57zm95.51 0L286 235h42.56L435.62 1h-42.57z" />
        </svg>
      </div>
      <section className="s-container t-hero t-hero--front">
        <PageHeader lede={t('HomePage.Banner.heading')}>
          <Link className="t-hero__link" to="/thesis/">
            {t('HomePage.Banner.link')}
          </Link>
        </PageHeader>
        <DownArrow />
      </section>
      {/* Use `dangerouslySetInnerHTML` because React wants to strip `muted`. */}
      <Parallax speed={-4} className="t-hero-video">
        <VideoBlock>
          <div
            id="hero-video"
            aria-hidden="true"
            dangerouslySetInnerHTML={{
              __html: `
                <video autoPlay playsInLine muted loop id="video-background">
                  <source src="/video/v-homepage.mp4" type="video/mp4"/>
                  <track src="/video/v-homepage.vtt" kind="captions" srclang="en" label="captions">
                  <track src="/video/v-homepage.vtt" kind="descriptions" srclang="en" label="captions">
                </video>
              `,
            }}
          />
        </VideoBlock>
      </Parallax>
      <section className="s-section s-container c-services">
        <SectionHeader
          heading={t('HomePage.ServicesSection.lede')}
          lede={t('HomePage.ServicesSection.heading')}
        />
        <Services />
      </section>
      <ColorSwitch />
      <Drift />
      <section
        className={`parent s-section s-container c-client-cards ${
          showAllClients ? 'c-client-cards--show-all-clients' : ''
        }`}
      >
        <SectionHeader
          heading={t('HomePage.RecentProjectsSection.lede')}
          lede={t('HomePage.RecentProjectsSection.heading')}
        />
        <div
          id="demo-video"
          aria-hidden="true"
          dangerouslySetInnerHTML={{
            __html: `
              <video autoPlay playsInLine muted loop id="video-demoreel">
                <source src="/video/demo-reel.mp4" type="video/mp4"/>
                <track src="/video/demo-reel.vtt" kind="captions" srclang="en" label="captions">
                <track src="/video/demo-reel.vtt" kind="descriptions" srclang="en" label="captions">
              </video>
            `,
          }}
        />
        <div className="c-filter c-filter--delayed">
          {clientFilters.map((item, index) => (
            <button
              key={index}
              className={filter === item ? 'active' : ''}
              onClick={() => setFilter(item)}
            >
              {t('HomePage.RecentProjectsSection.menu.' + index + '.menuItem')}
            </button>
          ))}
        </div>
        <ClientList
          clientList={clients}
          filter={filter}
          showAllClients={showAllClients}
          setShowAllClients={setShowAllClients}
        />
      </section>
      <section className="s-section s-container c-client-slider">
        <SectionHeader
          classes="t-section-heading--narrow"
          heading={t('HomePage.ClientStoriesSection.lede')}
          lede={t('HomePage.ClientStoriesSection.heading')}
        />
        <Block>
          <div>
            <div className="grid grid--2col-2-1 c-client-story">
              <div>
                <img
                  className="c-client-story__img"
                  src="/images/client-story--mast.jpg"
                  alt=""
                />
              </div>
              <div className="c-client-story__body">
                <div className="c-client-story__cite">
                  <p>
                    Rob Gaspar
                    <span className="color-secondary">Mast Hair</span>
                  </p>
                </div>
                <blockquote>
                  <span>
                    {t('HomePage.ClientStoriesSection.remarkClientStory')}
                  </span>
                </blockquote>
                <div>
                  {!isFrench ? (
                    <Link
                      className="c-client-slider__link"
                      to="/client-stories/"
                    >
                      {t('HomePage.ClientStoriesSection.readClientStory')}
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Block>
      </section>
      {/* {!isFrench ? (
        <NewsletterSignup
          heading={t('HomePage.LatestNewsSection.lede')}
          lede={t('HomePage.LatestNewsSection.heading')}
          placeholder={t('HomePage.LatestNewsSection.placeholder')}
          optIn={t('HomePage.LatestNewsSection.optIn')}
        >
          <div className="cards grid grid--3col">
            {links.map((link, index) => (
              <LinkCard key={index} card={link} />
            ))}
          </div>
        </NewsletterSignup>
      ) : null} */}
      <section className="s-section c-gallery">
        <div className="s-container">
          <SectionHeader
            heading={t('HomePage.LocationsSection.lede')}
            lede={t('HomePage.LocationsSection.heading')}
            placeholder={t('HomePage.LatestNewsSection.placeholder')}
          />
        </div>
        <Block>
          <ImageSlider slides={photos} />
        </Block>
      </section>
      <Prefooter />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query frontPageQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicClients(
      sort: { fields: [data___weight], order: ASC }
      filter: {
        data: { display_on_front_page: { eq: "Yes" } }
        lang: { eq: $language }
      }
    ) {
      edges {
        node {
          id
          data {
            name
            lede
            description
            tags
            urls {
              url
              url_text
            }
            company_type
            logo {
              url
            }
          }
        }
      }
    }
    allPrismicRecentLinks(filter: { lang: { eq: $language } }) {
      edges {
        node {
          data {
            links {
              description
              source
              url
              photo {
                url
                thumbnails {
                  link_300_1x {
                    url
                  }
                  link_300_2x {
                    url
                  }
                  link_500_1x {
                    url
                  }
                  link_500_2x {
                    url
                  }
                  link_700_1x {
                    url
                  }
                  link_700_2x {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicPhotoGallery {
      edges {
        node {
          data {
            photos {
              name
              photo {
                url
              }
            }
          }
        }
      }
    }
  }
`
